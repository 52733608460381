
import { computed, defineComponent, onMounted, ref } from "vue";
import * as Yup from "yup";
import {
  PrescriberProject,
  SelectedIndicator,
} from "@/store/modules/PrescriberModule";
import { useStore } from "vuex";
import { Actions } from "@/store/enums/StoreEnums";
import { Form, ErrorMessage, Field } from "vee-validate";
import Multiselect from "@vueform/multiselect";
import { routeLocationKey, useRouter } from "vue-router";
import { notify } from "@/core/helpers/globalJaya";

export interface PurposeChoice {
  number: number;
  description: string;
}
export default defineComponent({
  name: "prescriber-project-component",
  computed: {
    routeLocationKey() {
      return routeLocationKey;
    },
  },
  components: { ErrorMessage, Field, Form, Multiselect },
  props: {
    mode: { type: String, default: "display" },
    projectId: {
      type: Number,
      default: null,
    },
  },
  setup: function (props) {
    const store = useStore();
    const router = useRouter();
    const indicators = computed(() => store.getters.getIndicators);
    interface Region {
      id: number | null;
      name?: string;
      insee_reg?: number;
    }
    const regions = ref<Array<Region>>([]);
    onMounted(() => {
      if (props.mode === "update") {
        prescriberCharterAccepted.value = true;
      }
      if (props.mode === "display") {
        prescriberCharterAccepted.value = true;
      }
      if (props.projectId) {
        if (props.mode === "display-founders") {
          store
            .dispatch(Actions.GET_PRESCRIBER_PROJECT_FOUNDERS, props.projectId)
            .then((response) => {
              projectContent.value = response;
              const additional_required = projectContent.value
                .additional_indicators
                ? projectContent.value.additional_indicators
                    .filter((i) => i.required)
                    .map((i) => {
                      return i.indicator;
                    })
                : [];
              selectedMandatoryIndicators.value = additional_required;
              const additional_optionnal = projectContent.value
                .additional_indicators
                ? projectContent.value.additional_indicators
                    .filter((i) => !i.required)
                    .map((i) => {
                      return i.indicator;
                    })
                : [];
              selectedOptionalIndicators.value = additional_optionnal;
              if (projectContent.value.region?.length === 0) {
                projectContent.value.region = [null];
              }
            });
        } else {
          store
            .dispatch(Actions.GET_PRESCRIBER_PROJECT, props.projectId)
            .then((response) => {
              if (response.state === 3 && props.mode !== "display") {
                notify({
                  text: "Ce projet ne peut plus être mis à jour",
                  color: "warning",
                  duration: 3000,
                });
                router.push({ name: "prescriber-backoffice" });
              }
              projectContent.value = response;
              const additional_required = projectContent.value
                .additional_indicators
                ? projectContent.value.additional_indicators
                    .filter((i) => i.required)
                    .map((i) => {
                      return i.indicator;
                    })
                : [];
              selectedMandatoryIndicators.value = additional_required;
              const additional_optionnal = projectContent.value
                .additional_indicators
                ? projectContent.value.additional_indicators
                    .filter((i) => !i.required)
                    .map((i) => {
                      return i.indicator;
                    })
                : [];
              selectedOptionalIndicators.value = additional_optionnal;
              if (projectContent.value.region?.length === 0) {
                projectContent.value.region = [null];
              }
            });
        }
      }
      store.dispatch(Actions.FETCH_INDICATORS);
      store.dispatch(Actions.FETCH_GDPR_LAST_DOCS);
      store
        .dispatch(Actions.GET_LOCATIONS, { type: "regions", limit: 20 })
        .then((response) => {
          regions.value = response.results;
          regions.value.push({ name: "France entière", id: null });
        });
    });
    const projectContentValidator = Yup.object().shape({
      name: Yup.string()
        .required("Le champ ${label} est requis")
        .max(256, "Le champ ${label} ne doit pas dépasser ${max} caractères")
        .label("nom du projet")
        .nullable(),
    });
    const onSubmitProjectContent = async () => {
      projectContent.value.prescriber_charter =
        store.getters.getGdprLastDocs.find((doc) => doc.doc_type === 5).id;
      projectContent.value.prescriber = store.getters.currentUser.prescriber_id;
      if (projectContent.value.region) {
        projectContent.value.region = projectContent.value.region.filter(
          (reg) => {
            return reg !== null;
          }
        );
      }
      submitButtonProjectContent.value?.setAttribute("data-kt-indicator", "on");
      if (props.mode === "create") {
        store
          .dispatch(Actions.ADD_PRESCRIBER_PROJECT, projectContent.value)
          .then(async (response) => {
            //create new selected indicators
            for (let indicatorID of selectedMandatoryIndicators.value) {
              await store.dispatch(Actions.ADD_SELECTED_INDICATOR, {
                project: response.data.id,
                indicator: indicatorID,
                required: true,
              });
            }
            for (let indicatorID of selectedOptionalIndicators.value) {
              await store.dispatch(Actions.ADD_SELECTED_INDICATOR, {
                project: response.data.id,
                indicator: indicatorID,
                required: false,
              });
            }
            router.push({ name: "prescriber-backoffice" });
          })
          .catch(() => {
            submitButtonProjectContent.value?.removeAttribute(
              "data-kt-indicator"
            );
          });
      } else if (props.mode === "update") {
        store
          .dispatch(Actions.UPDATE_PRESCRIBER_PROJECT, projectContent.value)
          .then(async (response) => {
            let previousIndicatorMandatoryIds = [] as Array<number>;
            let previousIndicatorOptionalIds = [] as Array<number>;
            if (projectContent.value.additional_indicators) {
              previousIndicatorMandatoryIds =
                projectContent.value.additional_indicators
                  .filter((i) => i.required)
                  .map((i) => i.indicator);
              previousIndicatorOptionalIds =
                projectContent.value.additional_indicators
                  .filter((i) => !i.required)
                  .map((i) => i.indicator);
              // Destroy elements that are no longer in the selected ones
              for (let indicatorToDestroy of projectContent.value.additional_indicators.filter(
                (e) =>
                  e.required &&
                  !selectedMandatoryIndicators.value.includes(e.indicator)
              )) {
                await store.dispatch(
                  Actions.DESTROY_SELECTED_INDICATOR,
                  indicatorToDestroy.id
                );
              }
              for (let indicatorToDestroy of projectContent.value.additional_indicators.filter(
                (e) =>
                  !e.required &&
                  !selectedOptionalIndicators.value.includes(e.indicator)
              )) {
                await store.dispatch(
                  Actions.DESTROY_SELECTED_INDICATOR,
                  indicatorToDestroy.id
                );
              }
            }
            //create new selected indicators
            for (let indicatorID of selectedMandatoryIndicators.value.filter(
              (e) => !previousIndicatorMandatoryIds.includes(e)
            )) {
              await store.dispatch(Actions.ADD_SELECTED_INDICATOR, {
                project: response.data.id,
                indicator: indicatorID,
                required: true,
              });
            }
            for (let indicatorID of selectedOptionalIndicators.value.filter(
              (e) => !previousIndicatorOptionalIds.includes(e)
            )) {
              await store.dispatch(Actions.ADD_SELECTED_INDICATOR, {
                project: response.data.id,
                indicator: indicatorID,
                required: false,
              });
            }
            router.push({ name: "prescriber-backoffice" });
          })
          .catch(() => {
            submitButtonProjectContent.value?.removeAttribute(
              "data-kt-indicator"
            );
          });
      } else if (props.mode === "display") {
        let previousIndicatorMandatoryIds = [] as Array<number>;
        let previousIndicatorOptionalIds = [] as Array<number>;
        if (projectContent.value.additional_indicators) {
          previousIndicatorMandatoryIds =
            projectContent.value.additional_indicators
              .filter((i) => i.required)
              .map((i) => i.indicator);
          previousIndicatorOptionalIds =
            projectContent.value.additional_indicators
              .filter((i) => !i.required)
              .map((i) => i.indicator);
          // Destroy elements that are no longer in the selected ones
          for (let indicatorToDestroy of projectContent.value.additional_indicators.filter(
            (e) =>
              e.required &&
              !selectedMandatoryIndicators.value.includes(e.indicator)
          )) {
            await store.dispatch(
              Actions.DESTROY_SELECTED_INDICATOR,
              indicatorToDestroy.id
            );
          }
          for (let indicatorToDestroy of projectContent.value.additional_indicators.filter(
            (e) =>
              !e.required &&
              !selectedOptionalIndicators.value.includes(e.indicator)
          )) {
            await store.dispatch(
              Actions.DESTROY_SELECTED_INDICATOR,
              indicatorToDestroy.id
            );
          }
        }
        //create new selected indicators
        for (let indicatorID of selectedMandatoryIndicators.value.filter(
          (e) => !previousIndicatorMandatoryIds.includes(e)
        )) {
          await store.dispatch(Actions.ADD_SELECTED_INDICATOR, {
            project: projectContent.value.id,
            indicator: indicatorID,
            required: true,
          });
        }
        for (let indicatorID of selectedOptionalIndicators.value.filter(
          (e) => !previousIndicatorOptionalIds.includes(e)
        )) {
          await store.dispatch(Actions.ADD_SELECTED_INDICATOR, {
            project: projectContent.value.id,
            indicator: indicatorID,
            required: false,
          });
        }
        router.push({ name: "prescriber-backoffice" });
      }
    };
    const prescriberCharterAccepted = ref<boolean>(false);
    const submitButtonProjectContent = ref<HTMLButtonElement | null>(null);
    const projectContent = ref<PrescriberProject>({
      id: null,
      name: "",
      details: "",
      prescriber: store.getters.currentUser.prescriber_id,
      gdpr_purposes: [],
      users: null,
      prescriber_charter: null,
      state: 1,
      partners: "",
      project_purposes: [],
      region: [],
    });

    const selectedMandatoryIndicators = ref<number[]>([]);
    const selectedOptionalIndicators = ref<number[]>([]);

    const gdprPurposes = ref<PurposeChoice[]>([
      {
        number: 1,
        description: "Usage interne",
      },
      {
        number: 2,
        description: "Usage externe anonyme",
      },
      {
        number: 3,
        description: "Usage externe identifiant",
      },
    ]);
    const projectPurposes = ref<PurposeChoice[]>([
      {
        number: 1,
        description: "Sensibiliser les producteurs-rices",
      },
      {
        number: 2,
        description: "Animer un-des groupes de progrès",
      },
      {
        number: 3,
        description:
          "Formation des producteurs.rices (individuelle ou collective)",
      },
      {
        number: 4,
        description: "Formation de conseillers",
      },
      {
        number: 5,
        description: "Formation de futurs actifs agricoles",
      },
      {
        number: 6,
        description:
          "Mettre en place un plan de progrès/ un projet d'amélioration de pratiques",
      },
      {
        number: 7,
        description: "Préserver un patrimoine/ une ressource spécifique",
      },
      {
        number: 8,
        description:
          "Identifier les pratiques et/ou les services écosystémiques",
      },
      {
        number: 9,
        description: "Faciliter le dialogue et le croisement des connaissances",
      },
      {
        number: 10,
        description: "Autres (Préciser)",
      },
    ]);
    return {
      prescriberCharterAccepted,
      gdprPurposes,
      onSubmitProjectContent,
      projectContent,
      projectContentValidator,
      projectPurposes,
      regions,
      indicators,
      selectedMandatoryIndicators,
      selectedOptionalIndicators,
    };
  },
});
